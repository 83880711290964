import React from 'react'
import Layout from '../../components/Layout'
import alex from "../../../static/img/alex.jpg"
import omar from "../../../static/img/omar.jpg"
import marta from "../../../static/img/marta.jpg"
import coffee from "../../../static/img/logo.png"

export default class Index extends React.Component {
  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className='columns is-multiline'>
              <div className="column is-6-tablet is-3-desktop">
              <div class="card" style={{"height":"100%"}}>
                <div class="card-image">
                <img class="lazy" style={{"height":"200px","display":"block","margin-left":"auto","margin-right":"auto" }}
                src={alex} alt="Introduction to Machine Learning "></img>
                </div>
                <div class="card-content">
                  <div class="block"><small class="is-size-7">April 8, 2020</small>
                </div><h5 class="title is-6">Introduction to Machine Learning</h5>
                <p>We discussed the trends of Machine Lerning. Practical introduction to Linear Regression.</p>
                </div>
                </div>
              </div>
              <div className="column is-6-tablet is-3-desktop">
              <div class="card" style={{"height":"100%"}}>
                <div class="card-image">
                <img class="lazy" style={{"height":"200px","display":"block","margin-left":"auto","margin-right":"auto" }}
              src={omar} alt="Introduction to NLP's "></img>
                </div>
                <div class="card-content">
                  <div class="block"><small class="is-size-7">June 28, 2020</small>
                </div><h5 class="title is-6">Introduction to NLP's</h5>
                <p>On these lecture we discussed  how to start to work with NLPs, what essential skills do we need, and much more interesting information.</p>
                </div>
                </div>
              </div>
              <div className="column is-6-tablet is-3-desktop">
              <div class="card" style={{"height":"100%"}}>
                <div class="card-image">
                <img class="lazy" style={{"height":"200px","display":"block","margin-left":"auto","margin-right":"auto" }}
                src={marta} alt="Three Sentences That MUST Appear In Your Covering Letter "></img>
                </div>
                <div class="card-content">
                  <div class="block"><small class="is-size-7">July 12, 2020</small>
                </div><h5 class="title is-6">First Steps Towards Data Science</h5>
                <p>On this lecture we descussed how to start you jorney as a data scientist. </p>
                </div>
                </div>
              </div>
              <div className="column is-6-tablet is-3-desktop">
              <div class="card" style={{"height":"100%"}}>
                <div class="card-image">
                <img class="lazy" style={{"height":"200px","display":"block","margin-left":"auto","margin-right":"auto" }}
                src={coffee} alt="Three Sentences That MUST Appear In Your Covering Letter "></img>
                </div>
                <div class="card-content">
                  <div class="block"><small class="is-size-7">Soon</small>
                </div><h5 class="title is-6">New season coming ...</h5>
                <p>Much more lectures coming soon</p>
                </div>
                </div>
              </div>              
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
